import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Video from "../components/video"

import indexStyles from "../styles/pages/index.module.css"

import bgVideo from "../videos/landing-page-bg.mp4"
import bgPosterImg from "../images/bg-poster-img.png"
import aboutVideo from "../videos/header-vid.mp4"
import aboutPosterImg from "../images/about-poster-img.png"
import servicesSectionImg1 from "../images/services-section-img-1.jpg"
import servicesSectionImg2 from "../images/services-section-img-2.jpg"
import servicesSectionImg3 from "../images/services-section-img-3.png"
import servicesSectionImg4 from "../images/services-section-img-4.png"
import contactSectionImg from "../images/contact-section-img.jpg"

const IndexPage = () => (
    <Layout location="home">
      <SEO title="Home" />
      <div className={ indexStyles.aboutSection }>
        <div className={ indexStyles.aboutText }>
          <Video src={ bgVideo } poster={ bgPosterImg } className={ indexStyles.bgVideo } />
          <div className={indexStyles.aboutTextWrapper }>
            <h3>Win The Game</h3>
            <p>Business today competes in a constantly changing environment. If you want to stay in the complex game, live up to the new challenges and be a market leader.</p>
            <Link to="/about/">Learn More</Link>
          </div>
        </div>
        <div className={ indexStyles.logoAnimation }>
          <Video src={ aboutVideo } poster={ aboutPosterImg } className={ indexStyles.aboutVideo } />
        </div>
      </div>
      <div className={ indexStyles.servicesSection }>
        <div className={ indexStyles.servicesContent }>
          <div className={ indexStyles.topChevron }>
            <img src={ servicesSectionImg1 } alt=""/>
            <img src={ servicesSectionImg2 } alt=""/>
          </div>
          <div className={ indexStyles.bottomChevron }>
            <img src={ servicesSectionImg3 } alt=""/>
            <img src={ servicesSectionImg4 } alt=""/>
          </div>
        </div>
        <div className={ indexStyles.servicesText }>
          <h3>Fast-forward to the Future</h3>
          <p>We can provide the promotional staff you need in the shortest possible time. Kickstart your business with our services, which includes a train-&-deploy program for outsourced/promotional staff.</p>
          <Link to="/services/">Check Services</Link>
        </div>
      </div>
      <div className={ indexStyles.contactSection }>
        <div className={ indexStyles.contactText }>
          <h3>Work With Us</h3>
          <p>Get into the action of effective promotions.</p>
          <Link to="/contact/">Contact Us</Link>
        </div>
        <div className={ indexStyles.contactContent }>
          <img src={ contactSectionImg } alt=""/>
        </div>
      </div>
    </Layout>
)

export default IndexPage
